import {useMediaQuery} from "react-responsive";
import React, {useEffect, useState} from "react";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import {useNavigate} from "react-router";
import AuthModalLayout from "../../layout/AuthModalLayout/AuthModalLayout";
import MobileSubScreenLayout from "../../layout/MobileSubScreenLayout/MobileSubScreenLayout";
import Loader from "../../components/Loader/Loader";
import "./FAQDetails.scss";
import {useParams , Link} from "react-router-dom";
import cogoToast from "cogo-toast";
import {CustomToast} from "../../components/CustomToast/CustomToast";

const FAQDetails = () => {
    const param = useParams();

    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1024px)",
    });
    const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0);
        return () => {
        };
    }, []);

    return (
        <div className={"faq-details-page"}>
            {isDesktopOrLaptop ? (
                <AuthModalLayout>
                    <div
                        style={
                            isDesktopOrLaptop
                                ? {marginBottom: "100px"}
                                : {marginBottom: "78px"}
                        }
                    >
                        <div className={"navbar-wrapper margin-bottom-100"}>
                            <Navbar fixedNavbar={true}/>
                        </div>
                    </div>
                    <MainContent param={param}/>
                    {isDesktopOrLaptop && <Footer/>}
                </AuthModalLayout>
            ) : (
                <MobileSubScreenLayout back={() => navigate(-1)} title={"FAQ"}>
                    <MainContent param={param}/>
                </MobileSubScreenLayout>
            )}
        </div>
    );
};

export const MainContent = ({param}) => {
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1024px)",
    });
    const getParams = parseInt(param.id);
    const [faq, setFaq] = useState({})
    const [loading, setLoading] = useState(false);

//   const AllFaqsData1 = [
//     {
//       id: 1,
//       ques: "How to book ticket on NueGo ?",
//       ans: "On the Nuego App, enter the departure city, destination,select date of journey and search for all buses on that route. Select the appropriate time convenient for you, boarding and destination point and do the seat selection. Then proceed to book",
//     },
//     {
//       id: 2,
//       ques: "How can I download a booked ticket?",
//       ans: "For tickets booked via Nuego website or app, you can download the booked ticket by following the steps mentioned 1) sign into the app and click on manage booking 2) Go to upcoming trips & select the PNR 3)At the end of the PNR details, you will see an option of download ticket.",
//     },
//     {
//       id: 3,
//       ques: "What are the documents required while travelling by NueGo?",
//       ans: "You need to carry a govt authorized photo id along with SMS/Whatsapp/email of your ticket",
//     },
//     {
//       id: 4,
//       ques: "Do you need separate ticket for children?",
//       ans: "For children of 5 years and above, a separate ticket would be required and a seat would be issued accordingly",
//     },
//     {
//       id: 5,
//       ques: "What is your baggage policy?",
//       ans: "1 Medium suitcase or two small suitcases per guest is permitted. Excess charges will be levied incase of additional baggage.You are requested to visit the terms and conditions tab on the homepage for more details on the policy",
//     },
//     {
//       id: 6,
//       ques: "I missed my luggage/some contents of the luggage",
//       ans: "We would request you to get in touch with us via email on support@nuego.in or our 24*7 contact centre number 1800 267 9001",
//     },
//     {
//       id: 7,
//       ques: "How can I download invoice for a booked ticket?",
//       ans: "For tickets booked via Nuego website or app, you can download the booked ticket by following the steps mentioned  1) sign into the app and click on manage booking  2) Go to upcoming trips & select the PNR  3)At the end of the PNR details, you will see an option of view invoice .Upon clicking that tab, invoice will be available in pdf format",
//     },
//     {
//       id: 8,
//       ques: "My amount got debited but I did not get a confirmed ticket",
//       ans: "Incase your amount got debited but you have not got ticket confirmation, we request you to mail us on support@nuego.in with all the relevant details of the transaction",
//     },
//     {
//       id: 9,
//       ques: "How to reschedule a ticket?",
//       ans: "For tickets booked via Nuego website or app, you can reschedule the ticket by following the steps mentioned 1) sign into the app and click on manage booking 2) Go to upcoming trips & select the PNR that needs to be reschedule 3)At the end of the PNR details, you will see an option of change booking. Click on it and you will get the option to reschedule the booking",
//     },
//     {
//       id: 10,
//       ques: "How to modify a ticket?",
//       ans: "For tickets booked via Nuego website or app, you can modify the ticket by following the steps mentioned	1) sign into the app and click on manage booking	2) Go to upcoming trips & select the PNR that needs to be modified	3)At the end of the PNR details, you will see an option of change booking. Click on it and you will get the option to modify.Currently you can modify the pickup/drop point and the mobile number",
//     },
//     {
//       id: 11,
//       ques: "Are there any charges for rescheduling ticket?",
//       ans: "Rescheduling charges are Rs 50+ GST. For more details you can visit the terms and conditions tab on the homepage",
//     },
//     {
//       id: 12,
//       ques: "How to cancel a ticket?",
//       ans: "For tickets booked via Nuego website or app, you can cancel the ticket by following the steps mentioned 1) sign into the app and click on manage booking 2) Go to upcoming trips & select the PNR that needs to be cancelled 3)At the end of the PNR details, you will see an option of change booking. Click on it and you will get the option to cancel the booking",
//     },
//     {
//       id: 13,
//       ques: "What are the cancellation charges?",
//       ans: "Cancellation charges are applicable as per the cancellation policy. For more details you can visit the terms and condition tab on the homepage",
//     },
//     {
//       id: 14,
//       ques: "When do I get my refund post cancellation?",
//       ans: "For tickets booked via Nuego website or app, please note that the refund amount will be credited back to the source in 7 working days. For bookings done via other sources you need to get in touch with the respectivee operator",
//     },
//     {
//       id: 15,
//       ques: "How to book ticket on NueGo ?",
//       ans: "On the Nuego App, enter the departure city, destination,select date of journey and search for all buses on that route. Select the appropriate time convenient for you, boarding and destination point and do the seat selection. Then proceed to book",
//     },
//     {
//       id: 16,
//       ques: "My amount got debited but I did not get a confirmed ticket",
//       ans: "Incase your amount got debited but you have not got ticket confirmation, we request you to mail us on support@nuego.in with all the relevant details of the transaction",
//     },
//     {
//       id: 17,
//       ques: "What are the cancellation charges?",
//       ans: "Cancellation charges are applicable as per the cancellation policy. For more details you can visit the terms and condition tab on the homepage",
//     },
//   ];
//  const AllFaqsData = [
//   {
//       "id": 1,
//       "question": "How to book a ticket?",
//       "answer": "On the Nuego App, enter the departure city, destination,select date of journey and search for all buses on that route. Select the appropriate time convenient for you, boarding and destination point and do the seat selection. Then proceed to book."
//   },
//   {
//       "id": 4,
//       "question": "What are the documents required while travelling by NueGo?",
//       "answer": "You need to carry a govt authorized photo id along with SMS/Whatsapp/email of your ticket."
//   },
//   {
//       "id": 6,
//       "question": "What is your baggage policy?",
//       "answer": "1 Medium suitcase or two small suitcases per guest is permitted. Excess charges will be levied incase of additional baggage.You are requested to visit the terms and conditions tab on the homepage for more details on the policy."
//   },
//   {
//       "id": 7,
//       "question": "I missed my luggage/some contents of the luggage",
//       "answer": "We would request you to get in touch with us via email on support@nuego.in or our 24*7 contact centre number 1800 267 9001."
//   },
//   {
//       "id": 10,
//       "question": "How to reschedule a ticket?",
//       "answer": "For tickets booked via Nuego website or app, you can reschedule the ticket by following the steps mentioned 1) sign into the app and click on manage booking 2) Go to upcoming trips & select the PNR that needs to be reschedule 3)At the end of the PNR details, you will see an option of change booking. Click on it and you will get the option to reschedule the booking."
//   },
//   {
//       "id": 11,
//       "question": "How to modify a ticket?",
//       "answer": "For tickets booked via Nuego website or app, you can modify the ticket by following the steps mentioned 1) sign into the app and click on manage booking 2) Go to upcoming trips & select the PNR that needs to be modified 3)At the end of the PNR details, you will see an option of change booking. Click on it and you will get the option to modify.Currently you can modify the pickup/drop point and the mobile number.\n"
//   },
//   {
//       "id": 12,
//       "question": "Are there any charges for rescheduling ticket?\n",
//       "answer": "Rescheduling charges are Rs 50+ GST. For more details you can visit the terms and conditions tab on the homepage."
//   },
//   {
//       "id": 8,
//       "question": "How can I download invoice for a booked ticket?",
//       "answer": "For tickets booked via Nuego website or app, you can download the booked ticket by following the steps mentioned 1) sign into the app and click on manage booking 2) Go to upcoming trips & select the PNR 3)At the end of the PNR details, you will see an option of view invoice .Upon clicking that tab, invoice will be available in pdf format.\n"
//   },
//   {
//       "id": 2,
//       "question": "How can I download a booked ticket?",
//       "answer": "For tickets booked via Nuego website or app, you can download the booked ticket by following the steps mentioned 1) sign into the app and click on manage booking 2) Go to upcoming trips & select the PNR 3)At the end of the PNR details, you will see an option of download ticket."
//   },
//   {
//       "id": 9,
//       "question": "My amount got debited but I did not get a confirmed ticket",
//       "answer": "Incase your amount got debited but you have not got ticket confirmation, we request you to mail us on <a href = \"mailto: support@nuego.in\">Send Email</a> with all the relevant details of the transaction."
//   },
//   {
//       "id": 13,
//       "question": "How to cancel a ticket?",
//       "answer": "For tickets booked via Nuego website or app, you can cancel the ticket by following the steps mentioned 1) sign into the app and click on manage booking 2) Go to upcoming trips & select the PNR that needs to be cancelled 3)At the end of the PNR details, you will see an option of change booking. Click on it and you will get the option to cancel the booking."
//   },

//   {
//       "id": 5,
//       "question": "Do you need separate ticket for children?",
//       "answer": "For children of 5 years and above, a separate ticket would be required and a seat would be issued accordingly."
//   },
//   {
//       "id": 15,
//       "question": "When do I get my refund post cancellation?",
//       "answer": "For tickets booked via Nuego website or app, please note that the refund amount will be credited back to the source in 7 working days. For bookings done via other sources you need to get in touch with the respectivee operator."
//   },
//   {
//       "id": 14,
//       "question": "What are the cancellation charges?",
//       "answer": "Cancellation charges are applicable as per the cancellation policy. For more details you can visit the terms and condition tab on the homepage."
//   },
//   {
//       "id": 17,
//       "question": "Are Lounges available in all your cities?",
//       "answer": "Not yet, but we are working to add NueGo lounges in more cities soon. Details of our currently operational lounges are available on <a href=\"/lounge\">this page.</a>",
//   },
//   {
//     "id": 16,
//     "question": "What are the lounge timings and address?",
//     "answer": "Please see the timings and addresses for all our Lounges on <a href=\"/lounge\">this page.</a>"
// },
// ]
    // const filterData = AllFaqsData.filter((data) => {
    //   return data.id === getParams;
    // });

    useEffect(() => {
        fetch(`${process.env.REACT_APP_CMS_PROD_API}api/faqs/${getParams}`)
            .then(res => res.json())
            .then(res => {
                setFaq(res.data)
                setLoading(false)

            })
            .catch((err) => {
                if(err){
                    cogoToast.error(
                        <CustomToast
                            heading={"Error"}
                            message={err[0]?.message || err.toString()}
                            type={"error"}
                        />,
                    );
                }
            })
        setLoading(false)
    }, [])

    return (
        <div className={"main-content"}>
            {isDesktopOrLaptop && (
                <div className="px-3 pt-4 pb-1">
                    <span className="home">Home</span>
                    <span className="px-2">/</span>
                    <span className="support">Support</span>
                </div>
            )}
            {isDesktopOrLaptop && (
                <div className={"title ubuntu-700w-16s-28h px-3"}>Support</div>
            )}

            <div>
                {!loading ?
                   
                     <div className="px-3 ques-ans-display">
                        {faq  ?
                        <>
                        <h2 className="py-2">{faq?.attributes?.question}</h2>
                        <p className="pb-4" dangerouslySetInnerHTML={{__html: faq?.attributes?.answer}}></p>
                        </>
                        : <Link to='/faq'  >Click here for more <details></details></Link>
                        }
                    </div>
                
                    :
                    <Loader/>
                }
            </div>
        </div>
    );
};

export default FAQDetails;

import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import "./Navbar.scss";
import "../../assets/styles/common_styles.scss";
// other imports
import { setAuthType, setProfileData } from "../../store/Actions/AuthActions";
import {clearUserData,} from "../../store/Actions/CommonAction";
import cogoToast from "cogo-toast";
import {CustomToast} from "../CustomToast/CustomToast";
import {clearBookingDataOnLogoutAction} from "../../store/Actions/BookingAction";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { getProfileData } from "../../services/api/DataFetching";
import { motion } from "framer-motion";
import { logout } from "../../utils/utils";
import { store } from "../../index";
import { AnimatePresence } from "framer-motion";
import { useRef } from "react";

//icons
import hamburger from "../../assets/icons/hamburger.svg";
import Close from "../../assets/icons/close.svg";
import notification from "../../assets/icons/Notification.svg";
import offer from "../../assets/icons/Offers.svg";
import profile from "../../assets/icons/profile.svg";
import downArrow from "../../assets/icons/downArrow-green.svg";
import MyProfileIcon from "../../assets/icons/my-profile-icon.svg";
import MyPaymentIcon from "../../assets/icons/my-payment-icon.svg";
import logoutIcon from "../../assets/icons/logout-icon.svg";
import upcomingIcon from "../../assets/icons/upcoming-icons.svg";
import completedIcon from "../../assets/icons/completed-icons.svg";
import cancelledIcon from "../../assets/icons/cancell-icon.svg";
import searchIcon from "../../assets/icons/search_icon.svg"
import Whitelogo from "../../assets/icons/Logo_white.svg";
import Edit from "../../assets/icons/Edit.svg";
import Discount from "../../assets/icons/Discount.svg";
import Ticket from "../../assets/icons/Ticket.svg";
import Chat from "../../assets/icons/Chat.svg";
import Profile1 from "../../assets/icons/Profile1.svg";
import chevronright from "../../assets/icons/chevron-right.svg";
import tick from "../../assets/icons/tick.svg";
import upcoming from "../../assets/icons/upcoming.svg";
import Cancel from "../../assets/icons/Cancel - Solid.svg";
import wallet from "../../assets/icons/WalletWhite.svg";
import arrowDown from "../../assets/icons/keyboard_arrow_downdown-facing-cavet-grey.svg";


//assets
const rightArrowAccordion =
  process.env.REACT_APP_CDN_LINK + `assets/images/rightArrowAccordion.png`;
const logo_desktop =
  process.env.REACT_APP_CDN_LINK + `assets/images/Logologo_desktop.svg`;
const logo_mobile =
  process.env.REACT_APP_CDN_LINK + `assets/images/Logologo_desktop.svg`;
const rightArrow =
  process.env.REACT_APP_CDN_LINK + `assets/images/rightArrow.png`;

const Navbar = (props) => {
  const { setTypeToStore, removeFixed, profileDetail  ,isProfileUpdate} = props;
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1024 });
  let localStorageToken = localStorage.getItem("userToken");
  let isToken;
  if (store) {
    isToken =
      (localStorageToken !== undefined && localStorageToken?.length > 0) ||
      (store &&
        Object.keys(store.getState()?.AuthReducer?.tokenData??{})?.length > 0);
  }
  const navigate = useNavigate();
  let pageConstants;

  let typeFromStore = store.getState().AuthReducer.type;

  let pathname = useLocation().pathname;
  const [expanded, setExpanded] = useState("");
  const [expanded1, setExpanded1] = useState("");
  const [visibleProfileCard, setVisibleProfileCard] = useState(false);
  const [visibleBookingCard, setVisibleBookingCard] = useState(false);

  useEffect(() => {
    return () => {};
  }, [typeFromStore]);


  useEffect(() => {
    if (isToken && isProfileUpdate) {
      fetchProfileData();
    }
    return () => {};
  }, [isToken]);

  useEffect(() => {
    document.addEventListener("click", (e) => {
      // if (
      //   !e.target.closest(".booking-card-wrapper") &&
      //   !e.target.closest(".profile-card-toggler")
      // ) {
      //   // setVisibleBookingCard(false);
      //   setVisibleProfileCard(false);
      // }
      if(e.target.classList.contains("booking-toggle-icon")){
        setVisibleProfileCard(false);
        setVisibleBookingCard(true)
      }
      if(e.target.closest(".booking-card-wrapper")){
       setVisibleProfileCard(false);
       if( e.target.closest(".backdrop")){
        setVisibleBookingCard(false)
       }else{
        setVisibleBookingCard(true)
       }
      //  setVisibleBookingCard(true)
      }
      if(e.target.closest(".profile-card-toggler")){
        setVisibleBookingCard(false);
      }
    });
    return () => {
      
    };
  }, []);


  const fetchProfileData = () => {

    if (isToken) {
      let variable = {
        userId: localStorage.getItem("userID"),
        walletDetails:true,
      };
      getProfileData(
        variable,
        (response) => {
          if (response !== null) {
            store.dispatch(setProfileData(response));
          } else {
            store.dispatch(setProfileData({}));
          }
        },
        (error) => {
          store.dispatch(setProfileData({}));
          if(error){
            cogoToast.error(
                <CustomToast
                    heading={"Error"}
                    message={error[0]?.message || error.toString()}
                    type={"error"}
                />,
            );
          }
        }
      );
    } else {
      store.dispatch(setProfileData({}));
    }
  };

  if (isDesktopOrLaptop) {
    pageConstants = {
      logo: logo_desktop,
      offer: offer,
      wallet: wallet,
      notification: notification,
      hideOnMobile: "d-flex",
      marginLeft: "margin-left-18",
      marginRight: "margin-right-18",
    };
  } else {
    pageConstants = {
      logo: logo_mobile,
      whitelogo: Whitelogo,
      offer: offer,
      hamburger: hamburger,
      discount: Discount,
      close: Close,
      edit: Edit,
      ticket: Ticket,
      chat: Chat,
      profile1: Profile1,
      tick: tick,
      notification: notification,
      upcoming: upcoming,
      cancel: Cancel,
      wallet: wallet,
      hideOnMobile: " display-none ",
      marginLeft: "margin-left-14",
    };
  }


  const profileIconHandler = () => {
  
    if (isToken) {
      setVisibleProfileCard(!visibleProfileCard);
    } else {
      setTypeToStore("d-signin");
    }
  };

  const manageBookingHandler = () => {
    if (isToken) {
      navigate("/my-booking", { replace: false });
    } else {
      // isDesktopOrLaptop ? setTypeToStore("d-signin") : setTypeToStore("m-signin")
      isDesktopOrLaptop ? setTypeToStore("guest") : navigate("/guest-user");
    }
  };
  const myBookingHandler = () => {
    setVisibleBookingCard(true);
    // if (localStorage.getItem("userToken")?.length > 0) {
    //     navigate('/my-booking', {replace: false})
    // } else {
    //     // isDesktopOrLaptop ? setTypeToStore("d-signin") : setTypeToStore("m-signin")
    //     isDesktopOrLaptop ? setTypeToStore("guest") : navigate("/guest-user")
    // }
  };

  const logoutHandler = () => {
    store.dispatch(setProfileData({}));
    localStorageToken = "";
    setVisibleProfileCard(false);
    navigate("/", { replace: false });
    logout();
  };

  const RedirectToMyBookingPage = (url) => {
    navigate(url);
    setVisibleBookingCard(!visibleBookingCard);
  };

  const navRef = useRef();

  const showNavbar = () => {
    navRef.current.classList.toggle("responsive_nav");
    setExpanded("");
    setExpanded1(""); 
  };

  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const [showMoreOptions1, setShowMoreOptions1] = useState(false);



   const handleClick = () => {
    setExpanded(prevExpanded => (prevExpanded === '1' ? '' : '1'));
    
    setExpanded1('');
  };
   const handleClick1 = () => {
    setExpanded1(prevExpanded => (prevExpanded === '1' ? '' : '1'));
    setExpanded('');
  };
  
  const authHandler = () => {
    if (localStorage.getItem("userToken")) {
        localStorage.removeItem("userToken");
        store.dispatch(clearUserData());
        store.dispatch(clearBookingDataOnLogoutAction())
        cogoToast.success(
            <CustomToast
                type={"success"}
                message={"Logged out Successfully"}
                heading={"Success"}
            />,
            {position: "top-center"}
        );
    } else {
        if (isDesktopOrLaptop) {
            setTypeToStore("d-signin");
        } else {
            setTypeToStore("m-signin");
        }
    }
};


  return (
    <div
      className={
        "navbar-component d-flex justify-content-between align-items-center"
      }
      style={removeFixed ? { position: "relative" } : { position: "fixed" }}
    >
      <div>
        <img
          src={pageConstants.logo}
          alt={"alt"}
          className={"cursor-pointer"}
          style={
            isDesktopOrLaptop
              ? {
                  position: 'absolute',
                  width: '149px',
                  height: 'auto',
                  // top: 5px,
                  transform: 'translateY(-39%)'
                }
              : {}
          }
          onClick={() => navigate("/", { replace: false })}
        />
      </div>
      <div className={"d-flex align-items-center"} style={{marginRight:"10px"}}>
      {
      (!isDesktopOrLaptop && pathname==="/" )&&<img  src={searchIcon}  alt={'alt'} className={'icon-24'} style={{marginRight:"10px"}}
				     onClick={props.parent}/>
      }
      <img
          src={pageConstants.wallet}
          alt={"alt"}
          className={'icon-24'}
          onClick={() => localStorage.getItem("userToken") ? navigate("/wallet") : isDesktopOrLaptop ?setTypeToStore("d-signin"):setTypeToStore("m-signin") }
          style={{marginRight:"10px"}}
        />
        <img
          src={pageConstants.offer}
          alt={"alt"}
          className={"icon-24 "}
          onClick={() => navigate("/offers")}
        />
         {/* <img
          src={pageConstants.notification}
          alt={"alt"}
          onClick={() => !isToken && !isDesktopOrLaptop && setTypeToStore("m-signin")}
          className={`icon-24 ${pageConstants.marginLeft}`}
        /> */}

        {isDesktopOrLaptop && (
          <div className="booking-nav">
            <div className={`d-flex ${pageConstants.marginLeft} mr-4`}>
              <p
                className={
                  "open-400w-14s-22h mb-0 whitespace-nowrap cursor-pointer " +
                  (pathname === "/my-booking"
                    ? " teal-22BBB0-color "
                    : " white-color ")
                }
                onClick={() => myBookingHandler()}
              >
                My Bookings{" "}
              </p>
            </div>
            <motion.div
              variants={ArrowRotate}
              initial="visible"
              className="booking-card-toggler "
              animate={visibleBookingCard ? "hidden" : "visible"}
            >
              <img
                src={downArrow}
                onClick={() => myBookingHandler()}
                className={
                  " booking-toggle-icon " +
                 ( visibleBookingCard 
                    ? "cursor-pointer ml-2"
                    : "change-arrow-white cursor-pointer mr-2")
                }
                alt={"alt"}
              />
            </motion.div>
          </div>
        )}
        <motion.div
          variants={ProfileCard}
          initial="hidden"
          className="booking-card-wrapper"
          animate={visibleBookingCard ? "visible" : "hidden"}
        >
         {visibleBookingCard && <div
            className="backdrop"
            onClick={() => setVisibleBookingCard(false)}
          />}
          <div
            className={
              !isToken ? "booking-card" : "booking-card-local booking-card"
            }
          >
            <div
              onClick={() =>
                RedirectToMyBookingPage("/my-booking?=completed", {
                  replace: false,
                })
              }
              className={"cursor-pointer d-flex align-items-center mb-4"}
            >
              <img src={completedIcon} alt={"alt"} className={"mr-3"} />
              <div>
                <p className={"open-600w-16s-24h mb-1"}>Completed Trips</p>
              </div>
            </div>
            <div
              onClick={() =>
                RedirectToMyBookingPage("/my-booking?=upcoming", {
                  replace: false,
                })
              }
              className={" cursor-pointer d-flex align-items-center mb-4"}
            >
              <img src={upcomingIcon} alt={"alt"} className={"mr-3"} />
              <div>
                <p className={"open-600w-16s-24h mb-1"}>Upcoming Trips</p>
              </div>
            </div>
            <div
              onClick={() =>
                RedirectToMyBookingPage("/my-booking?=cancelled", {
                  replace: false,
                })
              }
              className={" cursor-pointer d-flex align-items-center"}
            >
              <img src={cancelledIcon} alt={"alt"} className={"mr-3"} />
              <p className={"open-600w-16s-24h mb-1"}>Cancelled Trips</p>
            </div>
          </div>
        </motion.div>

        {isDesktopOrLaptop && !isToken && (
          <div className={`d-flex ${pageConstants.marginLeft}`}>
            <p
              className={
                "open-400w-14s-22h mb-0 whitespace-nowrap cursor-pointer " +
                (pathname === "/my-booking"
                  ? " teal-22BBB0-color "
                  : " white-color ")
              }
              onClick={() => manageBookingHandler()}
            >
              Manage Bookings{" "}
            </p>
            {/*<img src={downfaceCavet} alt={'alt'} className={'icon-24'}/>*/}
          </div>
        )}

        <div
          onClick={() => profileIconHandler()}
          className={`${pageConstants.hideOnMobile} ${pageConstants.marginLeft} cursor-pointer profile-card-toggler`}
        >
          <div className={"d-flex align-items-center"}>
            {profileDetail?.user?.username && isToken && (
              <p
                className={
                  "open-700w-16s-24h white-color mb-0 mr-2 text-capitalize"
                }
              >
                {profileDetail.user.username}
              </p>
            )}
            <img
              src={
                profileDetail?.profilePicUrl && isToken
                  ? profileDetail?.profilePicUrl
                  : profile
              }
              alt={"alt"}
              className={"mr-2 profile-picture"}
            />
          </div>
          <motion.div
            variants={ArrowRotate}
            initial="visible"
            animate={visibleProfileCard ? "hidden" : "visible"}
          >
            <img
              src={downArrow}
              className={visibleProfileCard ? "" : "change-arrow-white"}
              alt={"alt"}
            />
          </motion.div>
          <motion.div
            variants={ProfileCard}
            initial="hidden"
            className="profile-card-wrapper"
            animate={visibleProfileCard ? "visible" : "hidden"}
          >
           {visibleProfileCard && <div
              className="backdrop"
              onClick={() => setVisibleProfileCard(false)}
            />}
            <div className={"profile-card"}>
              <div className={"d-flex align-items-center mb-4"}>
                <img src={MyProfileIcon} alt={"alt"} className={"mr-3"} />
                <div
                  onClick={() =>
                    navigate("/profile-detail", { replace: false })
                  }
                >
                  <p className={"open-600w-16s-24h mb-1"}>My Profile</p>
                  <p className={"open-400w-14s-22h mb-0"}>
                    Eco card, Personal Info, Passengers
                  </p>
                </div>
              </div>
              <div className={"d-flex align-items-center mb-4"}>
                <img src={MyPaymentIcon} alt={"alt"} className={"mr-3"} />
                <div onClick={() => navigate("/wallet", { replace: false })}>
                  <p className={"open-600w-16s-24h mb-1"}>My Payments</p>
                  <p className={"open-400w-14s-22h mb-0"}>
                    Wallet & other payments
                  </p>
                </div>
              </div>
              <div
                className={"d-flex align-items-center"}
                onClick={logoutHandler}
              >
                <img src={logoutIcon} alt={"alt"} className={"mr-3"} />
                <p className={"open-600w-16s-24h mb-1"}>Logout</p>
              </div>
            </div>
          </motion.div>
        </div>

        {!isDesktopOrLaptop && (
          <header>
            <nav ref={navRef}>
              <div className="new1">
                <div className="whitelogo">
                  <img
                    src={pageConstants.whitelogo}
                    style={{ color: "black", marginRight: "10px" }}
                    alt={"alt"}
                    className={"cursor-pointer"}
                    onClick={() => navigate("/", { replace: false })}
                  />
                </div>
                <button className="nav-btn nav-close-btn" onClick={showNavbar}>
                  <img
                    src={pageConstants.close}
                    alt={"alt"}
                    className={"cursor-pointer"}
                    onClick={() => navigate("/", { replace: false })}
                  />
                </button>
              </div>

                    
              <div className="option" >
                <a
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    paddingTop: "0px",
                    paddingBottom: "35px",
                    justifyContent: "space-between",
                  }}
                  onClick={handleClick}
                >
                  <div >
                    <img
                      src={pageConstants.ticket}
                      style={{ color: "black", marginRight: "10px" }}
                      alt={"alt"}
                      className={"cursor-pointer"}
                      onClick={() => navigate("/", { replace: false })}
                    />
                    My Bookings
                  </div>
                  <motion.div
                    initial={false}
                    className={
                      "1" === expanded
                        ? "header-active"
                        : "header cursor-pointer"
                    }
                  >
                    <motion.div
                      animate={{ rotate: expanded === "1" ? -90 : 0 }}
                    >
                      <img
                        src={chevronright}
                        alt={"alt"}
                        className={"icon-24"}
                      />
                    </motion.div>
                  </motion.div>
                </a>

                <AnimatePresence initial={false}>
                  {"1" === expanded && (
                    <motion.div
                      key="content"
                      className={"content"}
                      initial="collapsed"
                      animate="open"
                      exit="collapsed"
                      variants={{
                        open: { opacity: 1, height: "auto", width: "100%" },
                        collapsed: { opacity: 0, height: 0 },
                      }}
                      transition={{
                        duration: 0.8,
                        ease: [0.04, 0.62, 0.23, 0.98],
                      }}
                    >
                      <div
                        className={"cursor-pointer"}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "start",
                          width: "100%",
                        }}
                      >
                        <a className="sub" 
                        onClick={()=>navigate('/my-booking?=completed')}
                        >
                          <div>
                          <img
                            src={pageConstants.tick}
                            alt={"alt"}
                            style={{
                              marginRight: "10px",
                              cursor: "pointer",
                              width: "28px",
                            }}
                            onClick={() =>
                              !isDesktopOrLaptop && setTypeToStore("m-signin")
                            }
                          />
                          Completed Trip
                          </div>
                          <img
                            src={rightArrowAccordion}
                            alt={"alt"}
                          />
                        </a>

                        <a className="sub"
                        onClick={()=>navigate('/my-booking?=upcoming')}
                        >
                          <div>
                          <img
                            src={pageConstants.upcoming}
                            alt={"alt"}
                            style={{
                              marginRight: "10px",
                              cursor: "pointer",
                              width: "28px",
                            }}
                            onClick={() =>
                              !isDesktopOrLaptop && setTypeToStore("m-signin")
                            }
                          />
                          Upcoming Trip
                          </div>
                          <img
                            src={rightArrowAccordion}
                            alt={"alt"}
                          />
                        </a>
                        <a className="sub" style={{marginBottom:"20px"}}
                        onClick={()=>navigate('/my-booking?=cancelled')}
                        >
                          <div>
                          <img
                            src={pageConstants.cancel}
                            alt={"alt"}
                            style={{
                              marginRight: "10px",
                              cursor: "pointer",
                              width: "28px",
                            }}
                            onClick={() =>
                              !isDesktopOrLaptop && setTypeToStore("m-signin")
                            }
                          />
                          Cancelled Trip
                          </div>
                          <img
                            src={rightArrowAccordion}
                            alt={"alt"}
                          />
                        </a>
                      </div>
                    </motion.div>
                  )}
                </AnimatePresence>
                <div
                  style={{
                    width: "100%",
                    height: "0.5px",
                    background: "rgba(0, 0, 0, 0.20)",
                    marginTop: "2px",
                    marginBottom: "2px",
                  }}
                ><p></p></div>

                { !isToken &&
                  <a
                  onClick={() => manageBookingHandler()}
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    paddingTop: "35px",
                    paddingBottom: "35px",
                    borderBottom:"1px solid rgba(0, 0, 0, 0.20)"
                  }}
                >
                  <img
                    src={pageConstants.edit}
                    style={{ color: "black", marginRight: "10px" }}
                    alt={"alt"}
                    className={"cursor-pointer"}
                    //onClick={() => navigate("/", { replace: false })}
                  />
                  Manage Bookings
                </a>
                }

                <a
                  onClick={() => navigate("/offers")}
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    paddingTop: "35px",
                    paddingBottom: "35px",
                    borderBottom:"1px solid rgba(0, 0, 0, 0.20)"
                  }}
                >
                  <img
                    src={pageConstants.discount}
                    alt={"alt"}
                    className={"icon-24 "}
                    style={{ color: "black", marginRight: "12px" }}
                    onClick={() => navigate("/offers")}
                  />
                  Offers
                </a>

                <a
                  onClick={()=>window.HaptikSDK.show()}
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    paddingTop: "35px",
                    paddingBottom: "35px",
                    borderBottom:"1px solid rgba(0, 0, 0, 0.20)"
                  }}
                >
                  <img
                    src={pageConstants.chat}
                    style={{ color: "black", marginRight: "12px" }}
                    alt={"alt"}
                    className={"cursor-pointer"}
                    onClick={() => navigate("/", { replace: false })}
                  />
                  Support
                </a>
                <a
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    paddingTop: "35px",
                    paddingBottom: "35px",
                    justifyContent: "space-between",
                    
                  }}
                   onClick={handleClick1}
                >
                  <div >
                    <img
                      src={pageConstants.profile1}
                      style={{ color: "black", marginRight: "10px" }}
                      alt={"alt"}
                      className={"cursor-pointer"}
                      onClick={() => navigate("/", { replace: false })}
                    />
                    Profile
                  </div>
                  <motion.div
                    initial={false}
                    className={
                      "1" === expanded1
                        ? "header-active"
                        : "header cursor-pointer"
                    }
                  >
                    <motion.div
                      animate={{ rotate: expanded1 === "1" ? -90 : 0 }}
                    >
                      <img
                        src={chevronright}
                        alt={"alt"}
                        className={"icon-24"}
                      />
                    </motion.div>
                  </motion.div>
                </a>

                <AnimatePresence initial={false}>
                  {"1" === expanded1 && (
                    <motion.div
                      key="content"
                      className={"content"}
                      initial="collapsed"
                      animate="open"
                      exit="collapsed"
                      variants={{
                        open: { opacity: 1, height: "auto", width: "100%" },
                        collapsed: { opacity: 0, height: 0 },
                      }}
                      transition={{
                        duration: 0.8,
                        ease: [0.04, 0.62, 0.23, 0.98],
                      }}
                    >
                      <div
                        className={"cursor-pointer"}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "start",
                          width: "100%",
                        }}
                      >
                        <a className="sub"
                        onClick={()=>navigate("/profile-detail")}
                        >
                          My Profile
                          <img
                            src={rightArrowAccordion}
                            alt={"alt"}
                          />
                        </a>

                        <a className="sub"
                        onClick={()=>navigate("/wallet")}
                        >
                          My Payments
                          <img
                            src={rightArrowAccordion}
                            alt={"alt"}
                          />
                        </a>
                        <a className="sub" 
                        onClick={authHandler}
                        style={{ paddingBottom: "20px" }}>
                          {localStorage.getItem("userToken") ? "Logout" : "Login"}
                          <img
                            src={rightArrowAccordion}
                            alt={"alt"}
                          />
                        </a>
                      </div>
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            </nav>
            <button className="nav-btn" onClick={showNavbar}>
              <img
                src={pageConstants.hamburger}
                alt={"alt"}
                className={`icon-24 ${pageConstants.marginLeft}`}
              />
            </button>
          </header>
        )}
      </div>
    
      </div>
  );
};

const mapStateToProps = (state) => ({
  profileDetail: state.AuthReducer.profileData,
  loginTokenDetails: state.AuthReducer.tokenData,
});

const mapDispatchToProps = (dispatch) => ({
  setTypeToStore: (data) => dispatch(setAuthType(data)),
});

const ArrowRotate = {
  visible: {
    rotate: 180,
  },
  hidden: {
    rotate: 0,
  },
};

const ProfileCard = {
  visible: {
    display: "block",
    opacity: 1,
  },
  hidden: {
    display: "none",
    opacity: 0,
  },
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);

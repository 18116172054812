import React ,{useState}from 'react';
import './FeatureCard.scss';
import { Link , useNavigate} from 'react-router-dom';
import {useMediaQuery} from "react-responsive";
import cogoToast from "cogo-toast";
import { CustomToast } from "../../../../../components/CustomToast/CustomToast";
import { NewsletterUser } from '../../../../../services/api/DataUpdating';

const FeatureCard = () => {
  const [email , setEmail ] = useState('')
  const navigate = useNavigate();
  
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1024px)",
  });

  const activateNewsLetter = ()=>{
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if(emailRegex.test(email)){  
            let variables = {
              email: email,
            };
            NewsletterUser(variables, (response) => {
              cogoToast.success(
                <CustomToast
                  message={response.message}
                  type={"success"}
                  heading={"Success"}
                />,
                { position: "top-center" }
              );
              setEmail("");
            });
      }
      else{
        cogoToast.warn(
          <CustomToast
              type={"warn"}
              message={
                  'Please enter a valid email'
              }
              heading={"Warning"}
          />,
          { position: "top-center" }
      );
      }
  }

  const onChangeHandler= (e)=>{
      e.preventDefault();
      setEmail(e.target.value)
  }
  
  return (
  // <div className='feature-card'>
  //    <div className="grid-container">
  //       <Link to=''>
  //       <div
  //       style={{backgroundImage:`url(${ isDesktopOrLaptop ? 'https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/Blog%20Home%20Page/shutterstock_1863384703_Top%2020%20Solo%20Travel%20Destinations%20For%20Women_848%20X%20371%20px.jpg' : 'https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/Blog%20Home%20Page/shutterstock_1863384703_Top%2020%20Solo%20Travel%20Destinations%20For%20Women_mWeb_343%20X%20263%20px.jpg'})`}}
  //        className={`grid-item item1`}>
  //         {/* <img src={item.img} alt={item.imgAlt} /> */}
  //         <div className='mweb'>
  //           <p>{'Adventure'}</p>
  //           <h3>Top 20 Solo Travel Destinations For Women | NueGo</h3>
  //           <a>one min</a>
  //         </div>
         
  //       </div>

  //       </Link>
  //       <div
  //       style={{backgroundImage:`url(${isDesktopOrLaptop? 'https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/Blog%20Home%20Page/shutterstock_1598796481_20%20Places%20To%20Visit%20in%20India%20During%20Monsoon_381%20X%20587%20px.jpg' : 'https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/Blog%20Home%20Page/shutterstock_1598796481_20%20Places%20To%20Visit%20in%20India%20During%20Monsoon_mWeb_277%20X%20277%20px.jpg'})`}}
  //        className={`grid-item item2`}>
  //         {/* <img src={item.img} alt={item.imgAlt} /> */}
  //         <div className='mweb'>
  //           <p>{'Weekend Getaway'}</p>
  //           <h3>20 Places To Visit in India During Monsoon</h3>
  //           <a>one min</a>
  //         </div>       
  //       </div>
        
  //       <Link to=''>
  //       <div
  //       style={{backgroundImage:`url(${'https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/Blog%20Home%20Page/shutterstock_2299452663_Your%20Complete%20Guide%20to%20Places%20to%20Visit%20in%20Ujjain%20in%202024_277%20X%20277%20px.jpg'})`}}
  //        className={`grid-item item3`}>
  //         {/* <img src={item.img} alt={item.imgAlt} /> */}
  //         <div className='mweb'>
  //           <p>{'Divinity'}</p>
  //           <h3>Your Complete Guide to Places to Visit in Ujjain in 2024</h3>
  //           <a>one min</a>
  //         </div>
         
  //       </div>
  //       </Link>
  //       <div
  //        className={`grid-item card-newsletter`}>
  //         <h2>Stay updated! Sign up to our newsletter</h2>
  //         <p>Receive travel tips, destination guides, and exclusive offers directly to your inbox.</p>
  //        <div>
  //        <input type="email" placeholder="Enter your email" />
  //        <button>Sign Up</button>
  //        </div>
  //       </div>
  //       <Link to='/blog/things-to-do-in-pondicherry'>
  //       <div
  //       //style={{backgroundImage:`url('https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/Blog%20Home%20Page/shutterstock_2048357774_10%20Things%20To%20Do%20in%20Pondicherry%20in%202024%20That%20You%20Won't%20Find%20in%20Guidebooks_277%20X%20277%20px.jpg')`}}
  //        className={`grid-item item5`}>
  //         {/* <img src={item.img} alt={item.imgAlt} /> */}
  //         <div className='mweb'>
  //           <p>{'Hidden Gems'}</p>
  //           <h3>10 Things To Do in Pondicherry in 2024 That You Won't Find in Guidebooks</h3>
  //           <a>one min</a>
  //         </div>
         
  //       </div>
  //       </Link>
     
  //   </div>
  //  </div>

  <div className='feature-card'>
     <div className="grid-container">
      <div className='first-column'>
      {/* <Link to='/blog/solo-travel-destinations-for-women' style={{ textDecoration: 'none', display: 'block' }}> */}
        <div
        onClick={()=>navigate('/blog/solo-travel-destinations-for-women')}
        style={{backgroundImage:`url(${ isDesktopOrLaptop ? 'https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/Blog%20Home%20Page/shutterstock_1863384703_Top%2020%20Solo%20Travel%20Destinations%20For%20Women_848%20X%20371%20px.jpg' : 'https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/Blog%20Home%20Page/shutterstock_1863384703_Top%2020%20Solo%20Travel%20Destinations%20For%20Women_mWeb_343%20X%20263%20px.jpg'})`}}
         className={`first-column-first-card`}>
          {/* <img src={item.img} alt={item.imgAlt} /> */}
          <div className='mweb'>
            <p>{'Adventure'}</p>
            <h3>Top 20 Solo Travel Destinations For Women | NueGo</h3>
            <a>Four mins</a>
          </div>
         
        </div>
      {/* </Link> */}

        <div
         className={`card-newsletter`}>
          <h2>Stay updated! Sign up to our newsletter</h2>
          <p>Receive travel tips, destination guides, and exclusive offers directly to your inbox.</p>
         <div>
         <input type="email" value={email} onChange={(e)=>onChangeHandler(e) }required placeholder="Enter your email" />
         <button onClick={activateNewsLetter}>Sign Up</button>
         </div>
        </div>
      </div>
          <div
          onClick={()=>navigate('/blog/monsoon-tourist-places-in-india')}
          style={{backgroundImage:`url(${isDesktopOrLaptop? 'https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/Blog%20Home%20Page/shutterstock_1598796481_20%20Places%20To%20Visit%20in%20India%20During%20Monsoon_381%20X%20587%20px.jpg' : 'https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/Blog%20Home%20Page/shutterstock_1598796481_20%20Places%20To%20Visit%20in%20India%20During%20Monsoon_mWeb_277%20X%20277%20px.jpg'})`}}
          className={`grid-item item2`}>
            {/* <img src={item.img} alt={item.imgAlt} /> */}
            <div className='mweb'>
              <p>{'Weekend Trips'}</p>
              <h3>20 Places To Visit in India During Monsoon</h3>
              <a>Three mins</a>
            </div>       
          </div>
        
        <Link to='/blog/places-to-visit-in-ujjain'>
        <div
        style={{backgroundImage:`url(${'https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/Blog%20Home%20Page/shutterstock_2299452663_Your%20Complete%20Guide%20to%20Places%20to%20Visit%20in%20Ujjain%20in%202024_277%20X%20277%20px.jpg'})`}}
         className={`grid-item item3`}>
          {/* <img src={item.img} alt={item.imgAlt} /> */}
          <div className='mweb'>
            <p>{'Religious'}</p>
            <h3>Your Complete Guide to Places to Visit in Ujjain in 2024</h3>
            <a>Two mins</a>
          </div>
         
        </div>
        </Link>
        
        <Link to='/blog/things-to-do-in-pondicherry'>
        <div
        //style={{backgroundImage:`url('https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/Blog%20Home%20Page/shutterstock_2048357774_10%20Things%20To%20Do%20in%20Pondicherry%20in%202024%20That%20You%20Won't%20Find%20in%20Guidebooks_277%20X%20277%20px.jpg')`}}
         className={`grid-item item5`}>
          {/* <img src={item.img} alt={item.imgAlt} /> */}
          <div className='mweb'>
            <p>{'Hidden Gems'}</p>
            <h3>10 Things To Do in Pondicherry in 2024 That You Won't Find in Guidebooks</h3>
            <a>Three mins</a>
          </div>
         
        </div>
        </Link>
     
    </div>
   </div>
    

)};

export default FeatureCard;

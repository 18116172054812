import React, {useEffect, useRef, useState} from 'react'
import '../style.scss'
import {store} from "../../../index";
import {getAvailableBusFilterList, getDestinationList, getSourceList} from "../../../services/api/DataFetching";
import cogoToast from "cogo-toast";
import {CustomToast} from "../../../components/CustomToast/CustomToast";
import {addDaysToDate, convertDateWithMonth, convertToDateFormat, getCityName, getPointsInInt, convertDateToDayDateMonth,convertDateToDayDate} from "../../../utils/utils";
import {setRecentSearchAction, setRescheduleAction} from "../../../store/Actions/CommonAction";
import Calendar from 'react-calendar';
import SearchSuggestion from "./SearchSuggestion";
import CalenderWhite from "../../../assets/icons/Calendar-white.svg"
import fireBaloontype1 from '../../../assets/revamp/jhalartype1.png';
import fireBaloontype2 from '../../../assets/revamp/jhalartype2.png';
import BusImg from '../../../assets/revamp/mwebBusDiwali.png';


//assets
const mb_input_left_icon = process.env.REACT_APP_CDN_LINK + `assets/images/mb_input_left_icon.png`
const flip = process.env.REACT_APP_CDN_LINK + `assets/images/flip.png`
const calendar = process.env.REACT_APP_CDN_LINK + `assets/images/Calendar.png`
const search = process.env.REACT_APP_CDN_LINK + `assets/images/search.png`



const MobileTopBus = (props) => {
    const {
        navigate, setSourceAndDestinationToStore,
        sourcePointListFromStore,
        destinationPointListFromStore,
        viewCalendar,
        setViewCalendar,
        selectedSearchFromRecent,
        filterDataFromStore,
    } = props
    // constfilterDataFromStore = store.getState().FilterReducer.filter
    const test = false
    const [count, setCount] = useState(1)
    const [pasangercount, setpasangercount] = useState(1)
    const [startLocation, setstartLocation] = useState(sourcePointListFromStore?.length > 0 && localStorage.getItem('currentLocation') ? localStorage.getItem('currentLocation') : "")
    const [endLocation, setendLocation] = useState('')
    const [calenderbol, setcalenderbol] = useState("")
    const [activeStartLocation, setActiveStartLocation] = useState(null);
    const [activeEndLocation, setActiveEndLocation] = useState(null);

    const [calendarValue, setCalendarValue] = useState(new Date());

    const sourceInputRef = useRef(null)
    const destinationInputRef = useRef(null);
    const [inputSuggestionList , setInputSuggestionList] = useState([]);
    const [destinationSuggestionList, setDestinationSuggestionList] = useState([]);

    const [sourceInputSuggestion, setSourceInputSuggestion] = useState(false);
    const [destinationInputSuggestion, setDestinationInputSuggestion] = useState(false);
	const sourceInputSuggestiondivRef = useRef(null)
	const destinationInputSuggestiondivRef = useRef(null)

    let userName, userEmail, userId, userNmber ,moeId
    const recentSearchDataFromStore = store.getState().CommonReducer.recentSearch
    let localStorageToken = localStorage.getItem("userToken");
    let isToken;
    if (store) {
        isToken =
            (localStorageToken !== undefined && localStorageToken?.length > 0) ||
            (store &&
                Object.keys(store.getState().AuthReducer?.tokenData)?.length > 0);
    }

    useEffect(() => {
        userName = localStorage.getItem("userName")
        userEmail = localStorage.getItem("userEmail")
        userId = localStorage.getItem("userID")
        userNmber = localStorage.getItem("userNumber")
        moeId = localStorage.getItem("moeID");
        window.Moengage.track_event("bus_home", {
            "account_id": `${moeId === null ? "" : moeId}`,
            "platform": "mobile website",
            "page_viewed": "yes",
            "fullname": `${userName === null ? "" : userName}`,
            "mobile_number": `${userNmber === null ? "" : userNmber}`,
            "email": `${userEmail === null ? "" : userEmail}`,
            "isloggedin": `${userNmber === null ? 'no' : 'yes'}`,
            "origin": "web_header"
        });
    }, []);

    useEffect(() => {
        // setstartLocation(selectedSearchFromRecent.FromCityName ? selectedSearchFromRecent.FromCityName : "")
        // setendLocation(selectedSearchFromRecent.ToCityName ? selectedSearchFromRecent.ToCityName : "")
        // setActiveStartLocation(selectedSearchFromRecent.FromCityId ? selectedSearchFromRecent.FromCityId : "")
        // setActiveEndLocation(selectedSearchFromRecent.ToCityId ? selectedSearchFromRecent.ToCityId : "")
        setInputSuggestionList(sourcePointListFromStore);
        return () => {

        };
    }, [selectedSearchFromRecent]);

    useEffect(()=>{
        setDestinationSuggestionList(destinationPointListFromStore)
    },[destinationPointListFromStore]);

    useEffect(() => {
        getCityName();
        store.dispatch(setRescheduleAction(false)); // to avoid reschedule flow
        let sourceListId =
            sourcePointListFromStore?.length > 0
                ? sourcePointListFromStore[0].CMCityid
                : null;
        let variable = {
            searchTerm: "",
            cityId:
                !activeStartLocation
                    ? sourceListId
                    : activeStartLocation,        };
        if(sourceListId !== null  && activeStartLocation !== null) getDestinationList(variable);
    }, [activeStartLocation]);


    // const onChnageStartLocationFunction = (e) => {
    //     let valid

    //     try {
    //         if (e.target.value !== "") {
    //             valid = e.target.value.match(/^[A-Za-z ]+$/)
    //         } else {
    //             valid = ""
    //         }

    //     } catch (err) {
    //         valid = null
    //     }
    //     if (valid !== null) {
    //         e.preventDefault();
    //         // setStartLocationTyping(true)
    //         setstartLocation(e.target.value)
    //         let variable = {
    //             "searchTerm": e.target.value?.trim(),
    //         }
    //         getSourceList(variable)
    //     }

    // }

    const onChnageStartLocationFunction = (e) => {
        let valid;
        
        setDestinationInputSuggestion(false)
        setSourceInputSuggestion(true)
        setActiveStartLocation(null)



        try {
            if (e.target.value !== "") {
                valid = e.target.value.match(/^[A-Za-z ]+$/);
            } else {
                valid = "";
                setActiveEndLocation(null)
                setendLocation("")
            }
        } catch (err) {
            valid = null;
            
        }

        if (valid !== null) {
           
            e.preventDefault();
            // setStartLocationTyping(true)
            setstartLocation(e.target.value);
            let variable = {
                searchTerm: e.target.value?.trim(),
            };
            
            let result =  sourcePointListFromStore.filter(item=>((`${item.CMCityname}`).toLowerCase()).includes((e.target.value).toLowerCase()));
            if (result.length) setInputSuggestionList(result);
            else {
                setstartLocation('')
                setInputSuggestionList(sourcePointListFromStore)
            }
            
            
            inputSuggestionList.forEach(option => {
              if (option.CMCityname.trim().toLowerCase() === e.target.value) {
                setstartLocation(option.CMCityname);
                setActiveStartLocation(option.CMCityid)
                setActiveEndLocation(null)
                setendLocation("")
                setSourceInputSuggestion(false);
                setDestinationInputSuggestion(true) 
                destinationInputRef.current.focus(); 
              }
            });

        }
    };
    // const onChnageEndLocationFunction = (e) => {
    //     let valid

    //     try {
    //         if (e.target.value !== "") {
    //             valid = e.target.value.match(/^[A-Za-z ]+$/)
    //         } else {
    //             valid = ""
    //         }

    //     } catch (err) {
    //         valid = null
    //     }
    //     if (valid !== null) {
    //         e.preventDefault();
    //         // setEndLocationTyping(true)
    //         setendLocation(e.target.value)
    //         let sourceListId = sourcePointListFromStore?.length > 0 ? sourcePointListFromStore[0].CMCityid : ""

    //         if (sourceListId !== null) {
    //             let variable = {
    //                 "searchTerm": e.target.value?.trim(),
    //                 "cityId": !activeStartLocation ? sourceListId : activeStartLocation
    //             }
    //             if(sourceListId !== null  && activeStartLocation !== null) getDestinationList(variable)
    //         } else {
    //             cogoToast.warn(<CustomToast message="Your Source point was currently not Serviceable "
    //                                         type={"warn"}/>, {position: 'top-center'});
    //         }
    //     }

    // }

    const onChnageEndLocationFunction = (e) => {
        let valid;

        try {
            if (e.target.value !== "") {
                valid = e.target.value.match(/^[A-Za-z ]+$/);
            } else {
                valid = "";
            }
        } catch (err) {
            valid = null;
        }
        if (valid !== null) {
            e.preventDefault();
            // setEndLocationTyping(true)
            if(!activeStartLocation) {
                cogoToast.warn(
                    <CustomToast
                        message="please select source city from source list"
                        type={"warn"}
                    />,
                    {position: "top-center"}
                   
                );
                 setDestinationInputSuggestion(false);
                 setSourceInputSuggestion(true);
                 setstartLocation("")
                 sourceInputRef.current.focus();
                 return
            }
            setendLocation(e.target.value);
            let sourceListId =
                sourcePointListFromStore?.length > 0
                    ? sourcePointListFromStore[0].CMCityid
                    : null;
            if (sourceListId !== null) {
                let variable = {
                    searchTerm: e.target.value?.trim(),
                    cityId:
                        parseInt(!activeStartLocation
                            ? sourceListId
                            : activeStartLocation),
                };
                if(sourceListId !== null  && activeStartLocation !== null) getDestinationList(variable);
                let result =  destinationPointListFromStore.filter(item=>((`${item.CMCityname}`).toLowerCase()).includes((e.target.value).toLowerCase()));
            setDestinationSuggestionList(result);
                destinationSuggestionList.forEach(option => {
                    //|| (destinationInputSuggestion.length===1 && option.CMCityname.trim().toLowerCase().includes((e.target.value).toLowerCase()))
                    if (option.CMCityname.trim().toLowerCase() === e.target.value ) {
                        setendLocation(option.CMCityname);
                        setActiveEndLocation(option.CMCityid)
                      setDestinationInputSuggestion(false)
                    }
                  });
                
            } else {
                cogoToast.warn(
                    <CustomToast
                        type={"warn"}
                        heading={"Warning"}
                        message={"Your Source Point was currently not Serviceable"}
                    />,
                    {position: "top-center"}
                );
            }
        }
    }

   

    const ref = useRef()

    useEffect(() => {
        const checkIfClickedOutside = e => {
            // If the menu is open and the clicked target is not within the menu,
            // then close the menu
            if (viewCalendar && ref.current && !ref.current.contains(e.target)) {
                setViewCalendar(false)
            }
        }

        document.addEventListener("mousedown", checkIfClickedOutside)

        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside)
        }
    }, [viewCalendar])

	useEffect(() => {
		const checkIfClickedOutsideSourceInput = (e) => {
			// If the menu is open and the clicked target is not within the menu,
			// then close the menu
			if (sourceInputSuggestion && sourceInputSuggestiondivRef.current && !sourceInputSuggestiondivRef.current.contains(e.target)) {
				setSourceInputSuggestion(false);
			}
		};

		document.addEventListener("mousedown", checkIfClickedOutsideSourceInput);

		return () => {
			// Cleanup the event listener
			document.removeEventListener("mousedown", checkIfClickedOutsideSourceInput);
		};
	}, [sourceInputSuggestion]);


	useEffect(() => {
		const checkIfClickedOutsideDestinationInput = (e) => {
			// If the menu is open and the clicked target is not within the menu,
			// then close the menu
			if (destinationInputSuggestion && destinationInputSuggestiondivRef.current && !destinationInputSuggestiondivRef.current.contains(e.target)) {
				setDestinationInputSuggestion(false);
			}
		};

		document.addEventListener("mousedown", checkIfClickedOutsideDestinationInput);

		return () => {
			// Cleanup the event listener
			document.removeEventListener("mousedown", checkIfClickedOutsideDestinationInput);
		};
	}, [destinationInputSuggestion]);

    const flipfun = () => {
        setstartLocation(endLocation);
        setendLocation(startLocation);
        setActiveStartLocation(activeEndLocation)
        setActiveEndLocation(activeStartLocation)
    }

    // useEffect(() => {
    //     if (sourcePointListFromStore?.length > 0 && localStorage.getItem('currentLocation')) {
    //         setActiveStartLocation(sourcePointListFromStore[0].CMCityid)
    //         // setstartLocation(localStorage.getItem('currentLocation'))
    //     }

    // }, [sourcePointListFromStore]);


    // Get the current date
    const currentDate = convertDateToDayDate(Date.now());

    // Get tomorrow's date
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    const tomorrowDate = convertDateToDayDate(tomorrow);

    // Get the day after tomorrow's date
    const dayAfterTomorrow = new Date();
    dayAfterTomorrow.setDate(dayAfterTomorrow.getDate() + 2);
    const dayAfterTomorrowDate = convertDateToDayDate(dayAfterTomorrow);

    const todayfun = () => {

        setCount(1)
        setCalendarValue(new Date())
        setcalenderbol(convertDateWithMonth(new Date()))

    }

    const Tomorrowfun = () => {
        setCount(2)
        const today = new Date()
        const tomorrow = new Date(today)

        tomorrow.setDate(tomorrow.getDate() + 1)

        setCalendarValue(tomorrow)
        setcalenderbol(convertDateWithMonth(tomorrow))
    }

    const DayAfterTomorrowfun = () => {
        setCount(3);
        const today = new Date();
        const tomorrow = new Date(today);

        tomorrow.setDate(tomorrow.getDate() + 2);

        setCalendarValue(tomorrow);
        setcalenderbol(convertDateWithMonth(tomorrow));
    };

    useEffect(() => {

	}, [count]);


    const searchBusSubmission = () => {
        
        if (startLocation === "" || endLocation === "") {
            cogoToast.warn(<CustomToast message="Please fill Enter Departure & Enter Destination"
                                        type={"warn"}/>, {position: 'top-center'});
            return
        }

		let sourceListId = sourcePointListFromStore?.length > 0 ? sourcePointListFromStore[0].CMCityid : ""
		let destinationListId = destinationPointListFromStore.length > 0 ? destinationPointListFromStore[0].CMCityid : null


		if (sourceListId !== null && destinationListId !== null) {

            setSourceAndDestinationToStore({
                "sourcePoint": startLocation,
                "sourceId": activeStartLocation === "" ? sourceListId : activeStartLocation,
                "destinationPoint": endLocation,
                "destinationId": activeEndLocation === "" ? destinationListId : activeEndLocation,
                "passengersCount": pasangercount,
                "date": calenderbol !== "" ? calenderbol : convertDateWithMonth(calendarValue),
                "rawDate": calendarValue
            })

            window.Moengage.track_event("bus_search", {
                account_id: `${moeId === null ? "" : moeId}`,
                platform: "website",
                fullname: `${userName === null ? "" : userName}`,
                mobile_number: `${userNmber === null ? "" : userNmber}`,
                email: `${userEmail === null ? "" : userEmail}`,
                isloggedin: `${userNmber === null ? "no" : "yes"}`,
                from: `${startLocation}`,
                fromId: `${activeStartLocation}`,
                to: `${endLocation}`,
                toId: `${activeEndLocation}`,
                date: `${calendarValue}`,
                pax: `${pasangercount}`,
                origin: "Calendar",
            });

            // AFTER FILTER ->
            let variable = {
                "sourceId": parseInt(!activeStartLocation ? sourceListId : activeStartLocation),
                "destinationId": parseInt(!activeEndLocation ? destinationListId : activeEndLocation),
                "date": convertDateWithMonth(calendarValue),
                // getting filter data ->
                "seatType": filterDataFromStore.seatType.currentValue,
                "busType": filterDataFromStore.busType.currentValue,
                "busTiming": filterDataFromStore.busTiming.currentValue,
                "endPrice": filterDataFromStore.priceRange.currentValue.endPrice,
                "startPrice": filterDataFromStore.priceRange.currentValue.startPrice,
                "droppingPoints": getPointsInInt(filterDataFromStore.droppingPoints.currentValue),
                "boardingPoints": getPointsInInt(filterDataFromStore.boardingPoints.currentValue),
                "amenities": filterDataFromStore.amenities.currentValue,
                "sortBy": filterDataFromStore.sort.currentValue,

            }

            // getAvailableBusList(variable)
            getAvailableBusFilterList(variable, (response) => {
                let tempArray = []
                tempArray = tempArray.concat(recentSearchDataFromStore)

                let nonRepeatedArray = recentSearchDataFromStore.filter(item => {

                    return (item.FromCityId === variable.sourceId.toString() && item.ToCityId === variable.destinationId.toString()) && item
                })

                if (nonRepeatedArray.length === 0) {
                    if (recentSearchDataFromStore.length < 5) {
                        tempArray.push(response[0])
                        store.dispatch(setRecentSearchAction(tempArray))
                    } else {

                        // remove first element of the recentSearchDataFromStore and append the new variable
                        tempArray.shift()
                        tempArray.push(response[0])
                        store.dispatch(setRecentSearchAction(tempArray))
                    }
                }
            })
            setTimeout(() => {
                navigate('/booking', {replace: false})
            }, 1000)
        } else {
            cogoToast.warn(<CustomToast message="Your Source or Destination was not serviceable"
                                        type={"warn"}/>, {position: 'top-center'});
        }
    }

    const sourceInputActiveHandler = () => {
        setDestinationInputSuggestion(false)
        setSourceInputSuggestion(true)
        setInputSuggestionList(sourcePointListFromStore)
    }
    // const destinationInputActiveHandler = () => {
    //     setDestinationInputSuggestion(true)
    //     setSourceInputSuggestion(false)
    // }
    const destinationInputActiveHandler = () => {
        if (activeStartLocation && startLocation) {
        setDestinationInputSuggestion(true);
        setSourceInputSuggestion(false);
        } 
    else{ 
        if(startLocation && !activeStartLocation){
            cogoToast.warn(
                <CustomToast
                    message="please select valid source city from source list first"
                    type={"warn"}
                />,
                {position: "top-center"}
               
                )
             setDestinationInputSuggestion(false);
                setSourceInputSuggestion(true);
                setstartLocation("")
                sourceInputRef.current.focus()
        }
        else{cogoToast.warn(
        <CustomToast
            message="please select source city from source list first"
            type={"warn"}
        />,
        {position: "top-center"}
       
        )
     setDestinationInputSuggestion(false);
        setSourceInputSuggestion(true);
        setstartLocation("")
        sourceInputRef.current.focus()}
    }
    };

    return (
        <div className={'MobileTopBus'}>
            {props?.title &&<h1 className='title'>
            {props?.title}</h1>}
            <div className={'search'}>
                <div className={'row enter-place'}>
                    <div className={'col-2 pace_left_icon'}>
                        <img src={mb_input_left_icon} className={'mb_input_left_icon'}
                             alt={'mb_input_left_icon'}/>
                    </div>
                    <div className={'col-8 pl-0'}>
                        <div 
						ref={sourceInputSuggestiondivRef}
						className={'search-input-wrapper'} onClick={sourceInputActiveHandler}>
                            <input id={'search-source-mobile'} type={'text'}
                                // pattern={"[a-zA-Z]"}
                                   onChange={(e) => onChnageStartLocationFunction(e)}
                                   value={startLocation} placeholder={'Enter Departure'}
                                   ref={sourceInputRef}
                                   className={'start_location'}/>
                            {sourceInputSuggestion && !destinationInputSuggestion ?
                                <div className={'search-suggestion-wrapper'}>
                                    <SearchSuggestion searchedText={startLocation}
                                        // list={suggestionList}
                                                      list={inputSuggestionList}
                                                      setActive={(id, name) => {
                                                          setActiveStartLocation(id)
                                                          setstartLocation(name)
                                                          setendLocation("");
                                                          setActiveEndLocation(null);
                                                          setTimeout(() => {
                                                              setSourceInputSuggestion(false)
                                                              setDestinationInputSuggestion(true);
                                                          }, 500)
                                                      }}
                                                      active={activeStartLocation}
                                    /></div> : null}

                        </div>
                        <div ref={destinationInputSuggestiondivRef}
						className={'search-input-wrapper'} onClick={destinationInputActiveHandler}>
                            <input id={'search-destination-mobile'} type={'text'}
                                // pattern="[A-Za-z]"
                                   onChange={(e) => onChnageEndLocationFunction(e)}
                                   value={endLocation} placeholder={'Enter Destination'}
                                   ref={destinationInputRef}
                                   className={'end_location'}/>
                            {destinationInputSuggestion && !sourceInputSuggestion ?
                                <div className={'search-suggestion-wrapper'}>                                   
                                    <SearchSuggestion searchedText={endLocation}
                                        // list={suggestionList}
                                                      list={destinationSuggestionList}
                                                      setActive={(id, name) => {
                                                          setActiveEndLocation(id)
                                                          setendLocation(name)
                                                          setTimeout(() => {
                                                              setDestinationInputSuggestion(false)
                                                          }, 500)
                                                      }}
                                                      active={activeEndLocation}
                                    /></div> : null}
                        </div>

                    </div>
                    <div className={'col-2 pace_left_icon'}>
                        <img src={flip} onClick={() => flipfun()} className={'flip'} alt={'flip'}/>
                    </div>
                </div>
                <div className={'date'}>
                    <div className={'fast-date-select'}>
                        <div className={count === 1 ? 'selected-date today cursor-pointer' : ' today cursor-pointer'}
                             onClick={() => todayfun()}>
                                <span className='day'>
                                {currentDate[0]}
                                </span>
                                <br />
                                <span className='dated'>
                                {currentDate[1]}
                                </span>
                        </div>
                        <div className={count === 2 ? 'selected-date Tomorrow cursor-pointer' : "Tomorrow cursor-pointer"}
                             onClick={() => Tomorrowfun()}>
                                <span className='day'>
                                {tomorrowDate[0]}
                                </span>
                                <br />
                                <span className='dated'>
                                {tomorrowDate[1]}
                                </span>
                        </div>
                        <div className={count === 3 ? 'selected-date Tomorrow cursor-pointer' : "Tomorrow cursor-pointer"}
                             onClick={() => DayAfterTomorrowfun()}>
                                <span className='day'>
                                {dayAfterTomorrowDate[0]}
                                </span>
                                <br />
                                <span className='dated'>
                                {dayAfterTomorrowDate[1]}
                                </span>
                        </div>
                    </div>
                    <div className={count=== 4 ?'calender-parent-div d-flex align-items-center cursor-pointer':'d-flex align-items-center cursor-pointer' }>
                    <img 
                    src={count=== 4 ?CalenderWhite:calendar} 
                    className={'Calendar-icon '} 
                    alt={"Calendar"}
                    onClick={() => {
                               setCount(0)
                               setViewCalendar(!viewCalendar)
                     }}
                    />
                    <input type={"input"} value={convertDateToDayDateMonth(calendarValue)}
                           readOnly={true}
                           onClick={() => {
                               setCount(0)
                               setViewCalendar(!viewCalendar)
                           }} className={count=== 4 ?'border-less-input calender-active cursor-pointer':'border-less-input calender cursor-pointer'}/>

                    </div>
                    {
                        viewCalendar &&
                        <div className={'calendar-wrapper cursor-pointer'}>
                            <Calendar onChange={(e) => setCalendarValue(e.toString())}
                                      defaultValue={new Date()}
                                      value={convertToDateFormat(calendarValue)}
                                      minDate={new Date()}
                                      inputRef={ref}
                                      maxDate={addDaysToDate(new Date(), 30)}
                                      onClickDay={(e) => {

                                          // cogoToast.success(<CustomToast type={"success"} type={"loading"}
                                          //                                message={"Checking Delivery date availablity"}/>, {position: "top-center"})
                                          setTimeout(() => {
                                              // deliverySlotChecker(formik, convertDateReverse(e))
                                              setCount(4)
                                              setViewCalendar(false)
                                          }, 500)
                                      }}/>
                        </div>
                    }
                </div>
                <div className={'searct-btn'} onClick={() => searchBusSubmission()}>
                    <img src={search} className={'Search'} alt={'Search'}/>
                    Search Buses
                </div>
            </div>
            <img className="mwebsearchBarBusImage" src={BusImg} alt="" />
            <img className='jhalar3 jhalar-type1' src={fireBaloontype1} alt="" />
            <img className='jhalar2 jhalar-type1' src={fireBaloontype1} alt="" />
            <img className='jhalar1 jhalar-type1' src={fireBaloontype2} alt="" />
            <img className='jhalar4 jhalar-type1' src={fireBaloontype2} alt="" />
        </div>
    )
}

export default MobileTopBus

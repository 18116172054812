import "./K2K.scss";
import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router";

import {
  setDestinationPoint,
  setSourceAndDestination,
  setSourcePoint,
} from "../../store/Actions/BookingAction";

// reusable components
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import MobileSubScreenLayout from "../../layout/MobileSubScreenLayout/MobileSubScreenLayout";
import AuthModalLayout from "../../layout/AuthModalLayout/AuthModalLayout";

// components
import Banner from "./components/Banner/Banner";
import ChargingIndia from "./components/ChargingIndia/ChargingIndia";
import RouteComponent from "./components/RouteComponent/RouteComponent";
import TourSection from "./components/Tour";
import SignUpCard from "./components/SignupCard";

function K2K(props) {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1024px)",
  });
  const navigate = useNavigate();
  return (
    <div className="k2k-landing-page">
      <Helmet>
        <title>NueGo: Kashmir to Kanyakumari | K2K</title>
        <link
          rel="canonical"
          href="https://www.nuego.in/kashmir-to-kanyakumari"
          hreflang="en-IN"
        />
        <meta
          name="description"
          content="NueGo unveils the K2K Record Electric Bus Expedition, traveling over 4,000 km! The 1st EV bus brand to connect Kashmir to Kanyakumari."
        />
        <meta name="keywords" content=""></meta>
        <meta name="robots" content="index,follow" />
      </Helmet>
      {isDesktopOrLaptop ? (
        <AuthModalLayout>
          <Navbar />
          <MainContent isDesktopOrLaptop={isDesktopOrLaptop} props={props} />
          <Footer  />
        </AuthModalLayout>
      ) : (
        <MobileSubScreenLayout
          back={() => navigate(-1)}
          title={"Kashmir to Kanyakumari"}
        >
          <MainContent isDesktopOrLaptop={isDesktopOrLaptop} props={props} />
          <Footer  />
        </MobileSubScreenLayout>
      )}
    </div>
  );
}

export const MainContent = ({ props }) => {
  return (
    <div className="k2k-main-content">
      <Banner />
      <ChargingIndia />
      <RouteComponent />
      <TourSection />
      <SignUpCard />
    </div>
  );
};

const mapStateToProps = (state) => ({
  sourcePointFromStore: state.BookingReducer.sourcePoint,
  destinationPointFromStore: state.BookingReducer.destinationPoint,
  sourcePointListFromStore: state.BookingReducer.sourcePointList,
  destinationPointListFromStore: state.BookingReducer.destinationPointList,
  userDataFromStore: state.AuthReducer,
  filterDataFromStore: state.FilterReducer.filter,
  filterFromStore: state.FilterReducer.filter,
});
const mapDispatchToProps = (dispatch) => ({
  setSourcePointToStore: (data) => dispatch(setSourcePoint(data)),
  setDestinationPointToStore: (data) => dispatch(setDestinationPoint(data)),
  setSourceAndDestinationToStore: (data) =>
    dispatch(setSourceAndDestination(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(K2K);
